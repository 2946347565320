<template>
    <div class="app-header-toolbar app-header-transition fixed w-full bg-white"
         :style="{top: onTop ? 0 : '56px', boxShadow: isFlat ? null : '1px 3px 6px -3px rgba(0, 0, 0, 0.1)'}"
         :class="{'has-tools': !!$slots.tools, 'has-tabs': !!$slots.tabs, 'has-content': !!$slots.default}"
         v-scroll="handleScroll">
        <container v-if="$slots.default">
            <div class="h-52 md:h-72 flex items-center">
                <div class="flex-grow-x-1 overflow-hidden">
                    <slot/>
                </div>

                <btn v-if="itemLink && $device.isDesktop" :to="itemLink.to" :color="itemLink.color ?? props.color" :text="!itemLink.button" class="ml-8 text-sub">
					<span>{{ itemLink.text }}</span>
					<icon :icon="icons.next"/>
				</btn>
            </div>
        </container>

        <container v-if="$slots.tabs" xs-full sm-full>
            <div class="h-42 flex items-end">
                <slot name="tabs"/>
            </div>
        </container>

        <container v-if="$slots.tools" xs-full sm-full>
            <div class="h-42 flex w-full overflow-x-auto overflow-y-hidden border-box pt-5 sm:pt-0 pl-16 md:pl-0">
                <slot name="tools"/>
            </div>
        </container>
    </div>
</template>

<script setup lang="ts">

import {nextIcon} from "@spoferan/nuxt-spoferan/icons";

const {$breakpoint} = useNuxtApp();

const props = defineProps<{
	// Whether no shadow shall be added
	flat?: boolean,

	// Whether no shadow shall be added when on the very top of the page for mobile devices
	flatOnTopMobile?: boolean,

	// Whether to place the toolbar on the very top of the page instead of below the header
	// Used when the header gets hidden during scroll or shall not be displayed on a page
	onTop?: boolean,

	// An item link to display on desktop devices
	itemLink?: { to: string, text: string, color?: string, button?: boolean},

	color?: string,

}>();

const icons= {
	next: nextIcon
};

const scrollPosition = ref(0);
const isFlat = computed(() => {
	return props.flat || ((props.flatOnTopMobile && $breakpoint.smAndDown) && scrollPosition.value < 10)
});

function handleScroll() {
	scrollPosition.value = typeof window !== 'undefined' ? (window.scrollY as number) : 0;
}

</script>

<style lang="scss">
.app-header-toolbar {
    z-index: 51; // Above header for shadow overlay
}
</style>